<template>
  <div class="purchase-order">
    <QueryForm v-model="queryForm" :query-btn-loading="tableLoading" @query="handleQuery" @reset="handleQueryFormReset">
      <el-form-item label="BU">
        <Select
          v-model="queryForm.bu"
          api-key="buList"
          clearable
        />
      </el-form-item>
      <el-form-item label="Style">
        <Select
          v-model="queryForm.style"
          api-key="styleList"
          clearable
          :configuration="optionsConfig"
          :find-length="styleList.length"
        />
      </el-form-item>
      <el-form-item label="平台">
        <Select
          v-model="queryForm.platform"
          api-key="platformList"
          clearable
          @responseData="val=>platformList=val"
          @change="changePlatform"
        />
      </el-form-item>
      <el-form-item label="站点">
        <Select
          v-model="queryForm.site"
          :params="{ sellPlatformId:queryForm.platform}"
          ganged
          clearable
          :ganged-prompt="$t('page.selectPlaceholder') + ' 平台'"
          api-key="siteList"
        />
      </el-form-item>
      <el-form-item v-for="(item,index) in queryForm.detail" :key="index" :label="item.label">
        <!-- <Select
          v-model="item.username"
          api-key="newUserList"
          clearable
        /> -->
        <el-select v-model="item.username" clearable :placeholder="$t('page.selectPlaceholder')" filterable>
          <el-option
            v-for="i in userList"
            :key="i.employeeId"
            :label="i.username"
            :value="i.username"
          />
        </el-select>
      </el-form-item>
    </QueryForm>
    <el-row :gutter="10" class="mt-3 mb-3">
      <el-button type="primary" @click="handleAdd">新建</el-button>
      <el-button type="primary" @click="handleUpdateOperate('operate')">修改运营负责人</el-button>
      <el-button type="primary" @click="handleUpdateOperate('plan')">修改计划负责人</el-button>
      <el-button type="primary" @click="handleBatchUpdate">负责人批量替换</el-button>
      <el-button type="primary" @click="uploadDetailVisible=true">批量导入</el-button>
      <el-button v-permission="'delete'" type="primary" class="ml-2" :disabled="!multipleSelection.length" @click="handleDelete">删除</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="val => {multipleSelection=val}"
    >
      <el-table-column
        type="selection"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="bu"
        label="BU"
        min-width="90"
      />
      <el-table-column
        prop="style"
        label="Style"
        min-width="120"
      />
      <el-table-column
        prop="platform"
        label="平台"
        min-width="90"
      />
      <el-table-column
        prop="site"
        label="站点"
        min-width="80"
      />

      <el-table-column
        v-for="(col, index) in queryForm.detail"
        :key="index"
        :label="col.label"
      >
        <template slot-scope="scope">
          {{ handleRole(scope.row.detail,col) }}
        </template>
      </el-table-column>
    </el-table>
    <Upload v-model="uploadDetailVisible" file-name="配置管理模板" :columns="respDetailsColumns" :view-api="importPrincipalView" :submit-api="importPrincipal" :template-download-api="exportPrincipalTemplate" @refresh="handleQuery" />
    <Paging :pager="pager" end @pagination="pagerUpdate" />
    <Update v-model="updateOperationVisible" :row="row" :find-length="styleList.length" @refresh="handleQuery" />
    <BatchUpdate v-model="batchUpdateOperationVisible" :row="row" :find-length="styleList.length" @refresh="handleQuery" />
  </div>
</template>

<script>
import Upload from '../responsible-automatic/components/Upload'
import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import Paging from '@/components/Pagination'
// import ImportFile from '@/components/ImportFile'
import { tableColumns, respDetailsColumns } from '@/constant/tablecolumns'
import Update from './components/Update'
import BatchUpdate from './components/BatchUpdate'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryStyleList, userList } from '@/api/listSelection'
import { respConfigDelete, pagePrincipalList, exportPrincipalTemplate, importPrincipal, importPrincipalView } from '@/api/configuration'
export default {

  components: {
    QueryForm,
    Select,
    Paging,
    Upload,
    Update,
    BatchUpdate
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      exportPrincipalTemplate, importPrincipal, importPrincipalView, respDetailsColumns,
      dialogselectlist: [],
      uploadDetailVisible: false,
      detailData: [],
      platformList: [], styleList: [],
      optionsConfig: { key: 'id', label: 'styleName', value: 'styleName' },
      tableLoading: false, updateOperationVisible: false, batchUpdateOperationVisible: false, exportLoading: false,
      tableColumns,
      tableData: [],
      userList: [],
      file: '',
      importSubmitLoading: false,
      multipleSelection: [],
      queryForm: {
        bu: '',
        platform: '',
        site: '',
        detail: [
          { role: 1, label: 'Style运营负责人', username: '', newLabel: 'styleOperationPrincipal' },
          { role: 2, label: 'BU或市场负责人', username: '', newLabel: 'buOperationPrincipal' },
          { role: 3, label: 'BG运营负责人', username: '', newLabel: 'bgOperationPrincipal' },
          { role: 4, label: 'Style计划负责人', username: '', newLabel: 'stylePlanPrincipal' },
          { role: 5, label: 'BU计划负责人', username: '', newLabel: 'buPlanPrincipal' },
          { role: 6, label: 'BG计划负责人', username: '', newLabel: 'bgPlanPrincipal' },
          { role: 7, label: 'PJM', username: '', newLabel: 'pjm' }]
      },
      row: {},
      pager: {
        current: 1,
        size: 20,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      const { platform } = this.queryForm
      const platformName = this.platformList.find(item => item.id === platform)?.platformName
      return Object.assign({},
        this.pager,
        this.queryForm, { platform: platformName }
      )
    }
  },
  async created() {
    const { datas } = await queryStyleList()
    this.styleList = datas
    const { datas: data } = await userList()
    this.userList = data
  },
  mounted() {
    this.handleQuery()
  },
  methods: {
    handleDelete() {
      this.$confirm('此操作将删除选中数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { code, msg } = await respConfigDelete(this.multipleSelection)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.handleQuery()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async submitRespDatas() {
      try {
        this.importSubmitLoading = true
        const { code, msg } = await importPrincipal(this.dialogselectlist)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.uploadDeliveryVisible = false
          this.handleQuery()
        }
      } finally {
        this.importSubmitLoading = false
      }
    },
    handleRole(row, col) {
      return row ? row.find(e => e.role === col.role)?.usernameList.join(',') : ''
    },
    changePlatform() {
      this.queryForm.site = ''
    },
    async handleExport() {
      try {
        this.exportLoading = true
        const res = await exportPrincipalTemplate()
        const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream;charset=utf-8' }))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', '配置管理模板' + '.' + 'xlsx')
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(link.href)
      } finally {
        this.exportLoading = false
      }
    },
    findSameItem(acc, curArray) {
      return curArray.every(e => {
        return acc.includes(e)
      })
    },
    handleBatchUpdate() {
      if (this.multipleSelection.length) {
        // 处理原负责人下拉数据
        let arr = []
        const principalList = []
        this.multipleSelection.map(item => {
          arr = item.detail.reduce((acc, cur) => {
            !this.findSameItem(acc, cur.usernameList) ? cur.usernameList.map(e => !acc.includes(e) ? acc.push(e) : '') : acc
            return acc
          }, [])
          arr.map(e => !principalList.includes(e) ? principalList.push(e) : '')
        })
        this.row = Object.assign({}, { title: '负责人批量替换', multipleSelection: this.multipleSelection })
        this.batchUpdateOperationVisible = true
      } else {
        this.$message.warning('至少选中一条数据进行修改')
      }
    },
    handleAdd() {
      this.updateOperationVisible = true
      this.row = Object.assign({}, { title: '新建', type: 'add', page: 'resp' })
    },
    handleUpdateOperate(flag) {
      if (this.multipleSelection.length === 1) {
        this.updateOperationVisible = true
        this.row = Object.assign({}, { title: flag === 'operate' ? '修改运营负责人' : '修改计划负责人', type: 'edit', flag }, this.multipleSelection[0], { page: 'resp' })
      } else {
        this.$message.warning('只能选中一条数据进行修改')
      }
    },
    async uploadFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const { code, datas } = await importPrincipalView(form)
        if (code === 0) {
          this.detailData = datas
          this.uploadDetailVisible = true
          this.file = ''
        }
      } finally {
        this.$refs.uploadForm.clearFiles()
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this._queryLists()
    },
    handleQuery() {
      this.pager.current = 1
      this._queryLists()
    },
    async _queryLists() {
      try {
        this.tableLoading = true
        const { code, datas: { pager: { total }, records = [] }} = await pagePrincipalList(this.queryParams)
        if (code === 0) {
          // this.tableData = records.map(item => {
          //   item.detail.map(e => {
          //     item[[e.role]] = e['usernameList'].join(',')
          //   })
          //   return { ...item }
          // })
          this.tableData = records.map(item => {
            item.detail = item.detail.map(e => {
              item[[e.role]] = e['usernameList'].join(',')
              const newLabel = this.queryForm.detail.find(ee => ee.role === e.role)?.newLabel
              item[newLabel] = e['usernameList'].join(',')
              return { ...e, [e.role]: e['usernameList'], label: this.queryForm.detail.find(ee => ee.role === e.role)?.label }
            })
            return { ...item }
          })
          this.pager.total = total
        }
      } finally {
        this.tableLoading = false
      }
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    }
  }
}
</script>

<style>

</style>
